<template>
  <section class="flex pt-6 px-5 pb-5 border rounded border-grey-500">
    <div class="mr-12 w-full">
      <h2 class="text-black-900 text-2xl font-medium mb-4">
        {{ publisher.name }}
      </h2>
      <ul class="space-y-3">
        <li class="bg-grey-400 pt-4 px-5 pb-5 hover:shadow-sm">
          <div class="flex items-center justify-between mb-5">
            <router-link
              :to="{
                name: 'shortlistBooks',
                params: { id: shortlistId, publisherHandle: publisher.handle },
              }"
              class="
                flex
                items-center
                font-medium
                text-lg
                group
                hover:text-orange-900
              "
            >
              Shortlist of Books
              <span
                class="
                  mx-2
                  py-0.5
                  px-0.7
                  leading-none
                  rounded-xl
                  bg-black-900
                  text-white-900 text-xs
                  group-hover:bg-orange-900
                "
              >
                {{ publisher.bookCount }}
              </span>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5 5L1 1"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
          </div>
          <ul class="space-x-3 flex items-start">
            <li v-for="(book, i) of publisher.books" :key="i">
              <a
                class="block w-28"
                :href="`/books/${book.handle}`"
                @click.prevent="openBookModal(book.handle)"
              >
                <div class="mb-1 w-18 min-h-115">
                  <custom-image
                    :src="book.imageUrl"
                    imgClass="shadow-md h-auto"
                    :width="72"
                  />
                </div>

                <h3
                  class="
                    text-sm text-black-900
                    font-medium
                    mb-2
                    hover:text-orange-900
                    capitalize
                  "
                >
                  {{ book.title }}
                </h3>

                <div
                  v-for="(author, index) in book.author ? book.author : []"
                  :key="index"
                  class="
                    items-center
                    uppercase
                    font-medium
                    text-grey-700 text-sm
                    inline-block
                  "
                >
                  <span
                    class="hover:underline"
                    @click.stop.prevent="() => handleAuthorClick(author)"
                    >{{ author }}</span
                  >
                  <span v-if="index + 1 < book.author.length">, </span>
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="flex-shrink-0 min-w-300 flex flex-col">
      <div class="flex items-center justify-between mb-4">
        <h3 class="text-black-900 text-lg font-medium">Contacts</h3>
      </div>
      <ul class="space-y-4 mb-5">
        <!-- html likethis cause don't know api structure -->
        <li>
          <h4 class="text-black-900 font-medium text-base mb-2">
            Mailing Address
          </h4>

          <div class="text-black-600 text-base leading-5">
            {{ publisher.name }} <br />
            {{ publisher.addressLine1 }} <br />
            {{ publisher.addressLine2 }} <br />
            {{ publisher.country }} {{ publisher.postalCode }}
          </div>
        </li>
        <li>
          <h4 class="text-black-900 font-medium text-base mb-2">Telephone</h4>

          <div class="text-black-600 text-base hover:text-orange-900">
            <a :href="`tel:${publisher.organizationPhone}`">
              {{ publisher.organizationPhone }}
            </a>
          </div>
        </li>
        <li>
          <h4 class="text-black-900 font-medium text-base mb-2">E-mail</h4>

          <div class="text-black-900 text-base">
            <a
              class="underline hover:text-orange-900"
              :href="`mailto:${publisher.organizationEmail}`"
              >{{ publisher.organizationEmail }}
            </a>
          </div>
        </li>
      </ul>
      <!-- show button if only 1 book/catalog array in shortlist -->
      <base-button v-if="false" class="mt-auto">
        <svg
          class="mr-2"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 1V11M11 6L1 6"
            stroke="currentColor"
            stroke-width="1.6"
            stroke-linecap="round"
          />
        </svg>
        See more
      </base-button>
      <div class="flex flex-grow items-center justify-start space-x-4">
        <base-button
          colorScheme="orange"
          class="max-w-100 py-2 mt-auto"
          @click.native="
            $emit('exportToPdf', {
              slug: shortlistId,
              publisherSlug: publisher.id,
            })
          "
        >
          <span class="font-normal text-sm"> To PDF </span>
        </base-button>
        <base-button
          colorScheme="orange"
          class="max-w-100 py-2 mt-auto"
          @click.native="
            $emit('exportToCsv', {
              slug: shortlistId,
              publisherSlug: publisher.id,
            })
          "
        >
          <span class="font-normal text-sm"> To CSV </span>
        </base-button>
        <base-button
          colorScheme="orange"
          class="max-w-100 py-2 mt-auto"
          @click.native="
            $emit('exportToOnix', {
              slug: shortlistId,
              publisherSlug: publisher.id,
            })
          "
        >
          <span class="font-normal text-sm"> To ONIX </span>
        </base-button>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from "@/components/utils/Button.vue";
import CustomImage from "@/components/utils/CustomImage.vue";
import { UPDATE_BOOK_BASE_PAGE } from "@/store/actions.type";

export default {
  name: "CardShortlist",
  components: {
    "base-button": BaseButton,
    "custom-image": CustomImage,
  },
  props: {
    publisher: {
      type: Object,
      required: true,
    },
    shortlistId: {
      type: String,
    },
  },
  methods: {
    openBookModal(handle) {
      this.$store.dispatch(UPDATE_BOOK_BASE_PAGE, {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      });
      this.$router.push({ name: "book", params: { handle } });
    },
    handleAuthorClick(author) {
      this.$router.push({
        name: "search",
        query: {
          q: author.toLowerCase(),
          fa: encodeURIComponent(JSON.stringify([author])),
        },
      });
    },
  },
};
</script>
