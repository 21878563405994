export const getFilenameFromHeaders = (headers) => {
  if ("content-disposition" in headers && headers["content-disposition"]) {
    return headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/['"]/g, "");
  }

  return "";
};
