<template>
  <section class="flex pt-6 px-5 pb-5 border rounded border-grey-500">
    <div class="w-full">
      <h2 class="text-black-900 text-2xl font-medium mb-4">Catalogs</h2>
      <ul class="space-y-3">
        <li class="bg-grey-400 pt-4 px-5 pb-5 hover:shadow-sm">
          <div class="flex items-center justify-between mb-5">
            <router-link
              :to="{ name: 'shortlistCatalogs', params: { id: shortlistId } }"
              class="
                flex
                items-center
                font-medium
                text-lg
                group
                hover:text-orange-900
              "
            >
              Shortlist of Catalogs
              <span
                class="
                  mx-2
                  py-0.5
                  px-0.7
                  leading-none
                  rounded-xl
                  bg-black-900
                  text-white-900 text-xs
                  group-hover:bg-orange-900
                "
              >
                {{ catalogCount }}
              </span>
              &nbsp;
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5 5L1 1"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
          </div>
          <ul class="space-x-3 flex items-start">
            <li v-for="(catalog, i) of catalogs" :key="i">
              <router-link
                class="block w-28"
                :to="{ name: 'catalog', params: { handle: catalog.handle } }"
              >
                <div class="mb-1 w-18 min-h-115">
                  <img
                    :src="catalog.imageUrl"
                    :alt="catalog.name"
                    class="shadow-md h-auto"
                  />
                </div>

                <h3
                  class="
                    text-sm text-black-900
                    font-medium
                    mb-2
                    hover:text-orange-900
                  "
                >
                  {{ catalog.name }}
                </h3>

                <div
                  class="
                    flex
                    items-center
                    uppercase
                    font-medium
                    text-grey-700 text-sm
                  "
                >
                  <svg
                    class="mr-1"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.996 3.32949L11.839 2.47131C11.8175 2.35356 11.715 2.26812 11.5955 2.26812C11.595 2.26812 11.5947 2.26812 11.5942 2.26812C11.4741 2.26862 11.372 2.35517 11.3514 2.47341L9.93904 10.6108L2.74085 10.611C2.56837 10.611 2.40877 10.5397 2.29101 10.4103C2.15605 10.2616 2.08089 10.0398 2.09897 9.84978C2.15172 9.39178 2.48281 9.0592 2.88571 9.0592H8.8929C9.01362 9.0592 9.11688 8.97215 9.13694 8.85292L10.5433 0.538748C10.5554 0.46681 10.5353 0.393262 10.4882 0.337543C10.4412 0.281825 10.372 0.249756 10.2992 0.249756H4.29675C2.92744 0.249756 1.5907 1.36338 1.31669 2.73133L0.0341729 9.01103C-0.081226 9.74627 0.0978156 10.4374 0.538362 10.9576C0.970984 11.4683 1.60531 11.7497 2.32457 11.7497L2.43922 11.7493C2.44145 11.7493 2.44356 11.7494 2.44603 11.7494C2.82913 11.7498 10.1556 11.7503 10.232 11.7503C10.5092 11.7503 10.7455 11.5517 10.7945 11.2727L11.9972 3.41122C12.0015 3.38422 12.0009 3.35636 11.996 3.32949Z"
                      fill="#A0A0A4"
                    />
                  </svg>

                  {{ catalog.bookCount }}
                  titles
                </div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "CardShortlistCatalog",
  props: {
    catalogs: {
      type: Array,
      required: true,
    },
    catalogCount: {
      type: Number,
      default: 0,
    },
    shortlistId: {
      type: String,
    },
  },
};
</script>
