<template>
  <div>
    <div class="bg-white-900 mb-14 mt-4">
      <div class="container mx-auto flex items-start space-x-12">
        <aside class="max-w-290 w-full flex-shrink-0">
          <div
            class="
              flex flex-col
              border-t border-l border-r border-grey-500
              rounded-t rounded-l-none rounded-r-none
              py-4
              px-6
            "
          >
            <span
              class="
                text-base
                font-medium
                text-black-900
                tracking-tight
                leading-none
                mb-1
              "
              >{{ user.name }}</span
            >
            <a
              :href="`mailto:${user.email}`"
              class="text-sm text-grey-700 hover:text-orange-900"
            >
              {{ user.email }}
            </a>
          </div>

          <ul>
            <li
              v-for="shortlist of shortlists"
              :key="shortlist.id"
              @mousedown="handleChangeActiveShortlist(shortlist.id)"
              class="
                flex
                items-center
                pr-4
                border-l border-r border-t border-grey-500
                hover:bg-grey-400
                last:rounded-b last-before-rounded-b last:border-b
                relative
                before:empty-content
                before:absolute
                before:-m-px
                before:inset-0
                before:border
                before:pointer-events-none
                before:border-orange-900
                before:border-opacity-0
              "
              :class="{
                'before:border-opacity-100 bg-red-300':
                  activeShortlist && activeShortlist.id === shortlist.id,
              }"
              active-class="text-orange-900 before:border-opacity-100"
            >
              <!--  -->
              <router-link
                to="/shortlist"
                class="
                  flex
                  items-center
                  w-full
                  text-black-900 text-sm
                  font-medium
                  pl-6
                  py-4
                "
                :class="{
                  'text-orange-900':
                    activeShortlist && activeShortlist.id === shortlist.id,
                }"
              >
                {{ shortlist.name }}
                <span
                  class="
                    ml-2
                    py-0.5
                    px-0.7
                    leading-none
                    rounded-xl
                    bg-black-900
                    text-white-900 text-xs
                  "
                  :class="{
                    'bg-orange-900':
                      activeShortlist && activeShortlist.id === shortlist.id,
                  }"
                >
                  {{ shortlist.detailCount }}
                </span>
              </router-link>

              <div
                v-if="shortlist.isDefault"
                class="relative flex-shrink-0 ml-2 flex items-center"
              >
                <button
                  class="p-1 cursor-pointer hover:text-grey-700"
                  :class="{
                    'text-orange-900':
                      activeShortlist && activeShortlist.id === shortlist.id,
                  }"
                  @mousedown.stop="handleCreateShortlist"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1V13M13 7L1 7"
                      stroke="currentColor"
                      stroke-width="1.6"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </div>
              <div v-else class="relative flex-shrink-0 ml-2 flex items-center">
                <t-dropdown>
                  <button
                    class="p-1 cursor-pointer hover:text-grey-700"
                    :class="{
                      'text-orange-900':
                        activeShortlist && activeShortlist.id === shortlist.id,
                    }"
                    slot="trigger"
                    slot-scope="{
                      mousedownHandler,
                      blurHandler,
                      keydownHandler,
                    }"
                    @mousedown.stop="mousedownHandler"
                    @blur="blurHandler"
                    @keydown.stop="keydownHandler"
                  >
                    <svg
                      width="18"
                      height="4"
                      viewBox="0 0 18 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.3048 3.56522C15.4403 3.56522 14.7396 2.86445 14.7396 2C14.7396 1.13556 15.4403 0.434784 16.3048 0.434784C17.1692 0.434784 17.87 1.13556 17.87 2C17.87 2.86445 17.1692 3.56522 16.3048 3.56522Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.00042 3.56522C8.13598 3.56522 7.43521 2.86445 7.43521 2C7.43521 1.13556 8.13598 0.434784 9.00042 0.434784C9.86487 0.434784 10.5656 1.13556 10.5656 2C10.5656 2.86445 9.86487 3.56522 9.00042 3.56522Z"
                        fill="currentColor"
                      />
                      <path
                        d="M1.69608 3.56522C0.831631 3.56522 0.130859 2.86445 0.130859 2C0.130859 1.13556 0.831631 0.434784 1.69608 0.434784C2.56052 0.434784 3.26129 1.13556 3.26129 2C3.26129 2.86445 2.56052 3.56522 1.69608 3.56522Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>

                  <ul
                    class="
                      rounded
                      py-2
                      px-3
                      shadow-xxs
                      space-y-2
                      bg-white-900
                      w-max-content
                    "
                    slot-scope="{ blurHandler }"
                  >
                    <li>
                      <button
                        class="
                          flex
                          items-center
                          text-black-900 text-sm
                          font-medium
                          hover:text-grey-700
                          w-full
                        "
                        @blur="blurHandler"
                        @mousedown.stop="handleRemove(shortlist.id)"
                      >
                        <svg
                          class="mr-2 flex-shrink-0"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.22581 8.77027V18.1216C5.22581 19.7785 6.56895 21.1216 8.22581 21.1216H15.6452C17.302 21.1216 18.6452 19.7785 18.6452 18.1216V8.77027M9.87097 10.8243V16.5M14 10.8243V16.5M4 6.06757H9.16129M20 6.06757H14.8387M14.8387 6.06757V3.5C14.8387 2.94772 14.391 2.5 13.8387 2.5H10.1613C9.60901 2.5 9.16129 2.94772 9.16129 3.5V6.06757M14.8387 6.06757H9.16129"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        Delete Shortlist
                      </button>
                    </li>
                    <li>
                      <button
                        class="
                          flex
                          items-center
                          text-black-900 text-sm
                          font-medium
                          hover:text-grey-700
                          w-full
                        "
                        @mousedown.stop="
                          handleRename(shortlist.id, shortlist.name)
                        "
                        @blur="blurHandler"
                      >
                        <svg
                          class="mr-2 flex-shrink-0"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.76806 14.7229L6.20231 14.6678L6.76806 14.7229C6.77368 14.6653 6.79915 14.6114 6.8401 14.5704L16.7344 4.67616C16.832 4.57853 16.9903 4.57853 17.0879 4.67616L20.9831 8.57136C21.0807 8.66899 21.0807 8.82728 20.9831 8.92491L11.1007 18.8073C11.058 18.8501 11.0012 18.8759 10.9408 18.8799L6.62667 19.172C6.47258 19.1824 6.34598 19.052 6.36097 18.8983L5.61451 18.8255L6.36097 18.8983L6.76806 14.7229Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>

                        Rename
                      </button>
                    </li>
                  </ul>
                </t-dropdown>
              </div>
            </li>
          </ul>
        </aside>

        <main class="w-full">
          <div class="flex justify-between items-center mb-9">
            <div class="flex justify-between items-center mr-2">
              <h1 class="text-black-900 font-medium text-5xl">
                {{ activeShortlist ? activeShortlist.name : "" }}
              </h1>
              <button
                class="
                  text-base
                  font-medium
                  text-grey-700
                  ml-9
                  hover:text-black-900
                "
                @click="handleClear"
              >
                Clear All
              </button>
            </div>
            <div class="flex flex-grow items-center justify-end space-x-4">
              <base-button
                colorScheme="orange"
                class="max-w-100 py-2 mt-auto"
                @click.native="handleExportShortlistToPdf"
              >
                <span class="font-normal text-sm">Export PDF</span>
              </base-button>
              <base-button
                colorScheme="orange"
                class="max-w-100 py-2 mt-auto"
                @click.native="handleExportShortlistToCsv"
              >
                <span class="font-normal text-sm">Export CSV</span>
              </base-button>
              <base-button
                colorScheme="orange"
                class="max-w-100 py-2 mt-auto"
                @click.native="handleExportShortlistToOnix"
              >
                <span class="font-normal text-sm">Export ONIX</span>
              </base-button>
              <base-button
                class="max-w-230 text-black-900"
                @click.native="handleCreateShortlist"
              >
                <svg
                  class="mr-2"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 1V11M11 6L1 6"
                    stroke="currentColor"
                    stroke-width="1.6"
                    stroke-linecap="round"
                  />
                </svg>
                Create new shortlist
              </base-button>
            </div>
          </div>
          <div class="space-y-3">
            <card-shortlist-catalog
              v-if="
                activeShortlist &&
                activeShortlist.catalogs &&
                activeShortlist.catalogs.length > 0
              "
              :catalogs="activeShortlist.catalogs"
              :catalogCount="activeShortlist.catalogCount"
              :shortlistId="activeShortlist.id"
            />
            <template
              v-if="
                activeShortlist &&
                activeShortlist.publishers &&
                activeShortlist.publishers.length > 0
              "
            >
              <card-shortlist
                v-for="(publisher, i) of activeShortlist.publishers"
                :key="i"
                :publisher="publisher"
                :shortlistId="activeShortlist.id"
                @exportToPdf="handleExportShortlistByPublisherToPdf"
                @exportToCsv="handleExportShortlistByPublisherToCsv"
                @exportToOnix="handleExportShortlistByPublisherToOnix"
              />
            </template>
          </div>
        </main>
      </div>
    </div>

    <div class="vue-html2pdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfFile">
          <button @click.self="closePdf()">&times;</button>
          <iframe :src="pdfFile" width="100%" height="100%" />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fileDownload from "js-file-download";

import CardShortlist from "@/components/cards/CardShortlist.vue";
import CardShortlistCatalog from "@/components/cards/CardShortlistCatalog.vue";
import {
  FETCH_SHORTLISTS,
  GET_SHORTLIST,
  LOAD_SELECTED_SHORTLIST,
  CLEAR_SHORTLIST,
  REMOVE_SHORTLIST,
} from "@/store/actions.type";
import { ShortlistService } from "@/common/api.service";
import { getFilenameFromHeaders } from "@/common/util";

export default {
  name: "Shortlist",
  components: {
    "card-shortlist": CardShortlist,
    "card-shortlist-catalog": CardShortlistCatalog,
    // "base-select": () => import("@/components/utils/Select.vue"),
    "base-button": () => import("@/components/utils/Button.vue"),
  },
  async mounted() {
    await this.$store.dispatch(FETCH_SHORTLISTS);
    if (this.$route.params.id) {
      this.$store.dispatch(GET_SHORTLIST, this.$route.params.id);
    } else {
      this.$store.dispatch(GET_SHORTLIST, this.shortlists[0].id);
    }
  },
  data() {
    return {
      pdfFile: null,
    };
  },
  computed: {
    ...mapGetters(["user", "shortlists", "activeShortlist"]),
  },
  methods: {
    async handleChangeActiveShortlist(id) {
      this.$router.push({ name: "shortlist", params: { id } });
    },
    async handleCreateShortlist() {
      await this.$store.dispatch(LOAD_SELECTED_SHORTLIST, {
        shortlistIds: [],
        bookId: null,
        catalogId: null,
      });
      this.$modal.show("shortlist-modal", {
        component: "create-shortlist-modal",
      });
    },
    handleRename(id, oldName) {
      this.$modal.show("shortlist-modal", {
        component: "rename-shortlist-modal",
        id,
        oldName,
      });
    },
    handleClear() {
      this.$swal({
        title: "Do you want to clear this shortlist?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(CLEAR_SHORTLIST);
        }
      });
    },
    async handleRemove(id) {
      await this.$store.dispatch(REMOVE_SHORTLIST, id);
      if (this.$route.params.id === id) {
        this.$router.push({ name: "shortlist" });
      }
    },
    handleExportShortlistToPdf() {
      if (this.activeShortlist.id !== undefined) {
        this.exportShortlistToPdf(this.activeShortlist.id);
      } else {
        this.exportShortlistToPdf(this.shortlists[0].id);
      }
    },
    async exportShortlistToPdf(slug) {
      const { data } = await ShortlistService.getShortlistPdf(slug);
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    async handleExportShortlistToCsv() {
      const slug =
        this.activeShortlist.id !== undefined
          ? this.activeShortlist.id
          : this.shortlists[0].id;
      const { data, headers } = await ShortlistService.getShortlistCsv(slug);
      var fileName = getFilenameFromHeaders(headers);
      fileDownload(data, fileName);
    },
    async handleExportShortlistToOnix() {
      const slug =
        this.activeShortlist.id !== undefined
          ? this.activeShortlist.id
          : this.shortlists[0].id;
      const { data, headers } = await ShortlistService.getShortlistOnix(slug);
      var fileName = getFilenameFromHeaders(headers);
      fileDownload(data, fileName);
    },
    async handleExportShortlistByPublisherToPdf({ slug, publisherSlug }) {
      const { data } = await ShortlistService.getPublisherPdf(
        slug,
        publisherSlug
      );
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      this.pdfFile = blobUrl;
    },
    async handleExportShortlistByPublisherToCsv({ slug, publisherSlug }) {
      const { data, headers } = await ShortlistService.getPublisherCsv(
        slug,
        publisherSlug
      );
      var fileName = getFilenameFromHeaders(headers);
      fileDownload(data, fileName);
    },
    async handleExportShortlistByPublisherToOnix({ slug, publisherSlug }) {
      const { data, headers } = await ShortlistService.getPublisherOnix(
        slug,
        publisherSlug
      );
      var fileName = getFilenameFromHeaders(headers);
      fileDownload(data, fileName);
    },
    closePdf() {
      URL.revokeObjectURL(this.pdfFile);
      this.pdfFile = null;
    },
  },
};
</script>
